import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { useApi } from "../services/useApi";
import EditPlayerModal from "./EditPlayerModal";
import { currency, dt } from "./helpers";
import { usePathname } from "./hooks/hooks";
import SiteContext from "./SiteContext";
import "./Rounds.scss";

const Player = () => {
  const { api } = useContext(SiteContext);
  const { playerId } = useParams();
  const currentUrl = usePathname();

  const { loading: playerLoading, runApi: playerApi } = useApi({
    api: api.getPlayer
  });

  const { loading: roundsLoading, runApi: roundsApi } = useApi({
    api: api.getRoundsByPlayer
  });

  const { loading: transfersLoading, runApi: transfersApi } = useApi({
    api: api.getTransfersByPlayer
  });

  const [player, setPlayer] = useState();
  const [rounds, setRounds] = useState();
  const [transfers, setTransfers] = useState();
  const [selectedPlayerId, setSelectedPlayerId] = useState();

  useEffect(() => {
    if (!player && !playerLoading) {
      playerApi({ playerId }).then(d => setPlayer(d));
    }
    if (!rounds && !roundsLoading) {
      roundsApi({ playerId }).then(d => setRounds(d));
    }

    if (!transfers && !transfersLoading) {
      transfersApi({ playerId }).then(d => setTransfers(d));
    }
  }, [
    player,
    playerApi,
    playerId,
    playerLoading,
    rounds,
    roundsApi,
    roundsLoading,
    transfers,
    transfersApi,
    transfersLoading
  ]);

  const Result = ({ result }) => {
    if (!result) return "";

    const [posdata, div] = result.split("|");

    const [pos, tot] = posdata.split('/')

    return (
      <div className="round-position">
        <span className="round-position__pos">{pos}</span> <span className="round-position__tot">of {tot}</span>
        <small>{div}</small>
      </div>
    );
  };

  return (
    <>
      <Row>
        <Col>
          {player && rounds && transfers && (
            <div className="content">
              <h4>
                <Link
                  to={currentUrl}
                  onClick={() => {
                    setSelectedPlayerId(player.id);
                  }}
                >
                  {player.fn} {player.ln}
                </Link>
              </h4>
              <h5>{player.division}</h5>

              <div>PDGA: {player?.pdga || "none"}</div>

              <div>Credit: {currency.format(player.balance)}</div>

              <hr />
              <h5>Rounds</h5>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Round</th>
                    <th>
                      <div className="size-full">Division</div>
                      <div className="size-sm col__div">Div</div>
                    </th>
                    <th className="col__score">
                      <div className="size-full">Score</div>
                      <div className="size-sm">Sc</div>
                    </th>
                    <th>Position</th>
                    <th className="col__payout">Payout</th>
                    <th className="col__money">Credit Added</th>
                    <th className="col__money">Credit Used</th>
                  </tr>
                </thead>
                <tbody>
                  {rounds.map(d => {
                    return (
                      <tr key={d.d}>
                        <td className="col__date">
                          <Link className="size-full" to={`/rounds/${d.id}`}>
                            {dt(d.d)}
                          </Link>
                          <Link className="size-sm" to={`/rounds/${d.id}`}>
                            {dt(d.d, "stacked")}
                          </Link>
                        </td>
                        <td>
                          {d.n}
                          <div className="font-sm">{d.loc}</div>
                        </td>
                        <td className="col__div">{d.division}</td>
                        <td className="col__score">{d.score}</td>
                        <td>
                          <Result result={d?.result} />
                        </td>
                        <td className="col__payout">
                          {!!d.EntryPayout && currency.format(d.EntryPayout)}
                        </td>
                        <td className="col__money">
                          {!!d.credit_added && currency.format(d.credit_added)}
                        </td>
                        <td className="col__money">
                          {!!d.credit_used && currency.format(d.credit_used)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>

              <h5>Transfers</h5>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>From</th>
                    <th>To</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {transfers.map(d => {
                    return (
                      <tr key={d.d}>
                        <td>{dt(d.d)}</td>
                        <td>{!!d.amt && currency.format(d.amt)}</td>
                        <td>{d.f}</td>
                        <td>{d.t}</td>
                        <td>{d.description}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </Col>
      </Row>
      {selectedPlayerId && (
        <EditPlayerModal
          playerId={selectedPlayerId}
          onCancel={() => setSelectedPlayerId(null)}
          onComplete={({ values }) => {
            setPlayer({ ...player, ...values });
            setSelectedPlayerId(null);
          }}
        />
      )}
    </>
  );
};

export default Player;
