import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useApi } from "../services/useApi";
//import * as api from "../services/api";
import { Button, Row, Col, Modal } from "react-bootstrap";
import { currency, dt } from "./helpers";
import SiteContext from "./SiteContext";
import Table from "./Table";
import FormSetup from "./FormSetup";
import { sortByProperties } from "./helpers";


const Aces = () => {
  const { token, api } = useContext(SiteContext);

  const { loading: acesLoading, runApi: aceApi } = useApi({ api: api.getAces });

  const { loading: acePotLoading, runApi: acePotApi } = useApi({
    api: api.getAcePot
  });

  const [aces, setAces] = useState();
  const [aceTotal, setAceTotal] = useState();
  const [showAceModal, setShowAceModal] = useState();

  const [playerData, setPlayerData] = useState();
  const [players, setPlayers] = useState();

  const { loading: playersLoading, runApi: playerApi } = useApi({
    api: api.getPlayerSimpleList
  });

  const fieldData = [
    { name: "d", label: "Date", type: "date", required: true },
    {
      name: "player",
      label: "Player",
      type: "searchSelect",
      required: true,
      options: (players || [])
        .sort(sortByProperties([{ field: "label", asc: true }]))
        .map(d => ({ value: d.value, label: d.label }))
    },
    { name: "amt", label: "Amount", type: "text", required: true }
  ];

  
  useEffect(() => {
    if (!aces && !acesLoading) {
      aceApi().then(d => setAces(d));
    }
    if (!aceTotal && !acePotLoading) {
      acePotApi()
        .then(data => setAceTotal(data))
        .catch(() => setAceTotal(0));
    }

    if (!playersLoading && !players) {
      playerApi().then(data => {
        setPlayerData(data);
        setPlayers(
          data.map(o => ({
            key: o.id,
            label: `${o.fn} ${o.ln}`,
            value: o.id
          }))
        );
      });
    }
  }, [
    aceApi,
    acePotApi,
    acePotLoading,
    aceTotal,
    aces,
    acesLoading,
    playerApi,
    players,
    playersLoading
  ]);

  const onDelete = e => {
    if (e) {
      const c = window.confirm(
        `Are you sure you want to delete ${e.fn} ${e.ln}'s ace?`
      );
      if (c)
        api.deleteAce({ token, id: e.id }).then(response => {
          if (response?.data?.success) {
            setAces([...aces.filter(x => x.id !== e.id)]);
            // getEntries();
          }
        });
    }
  };

  const columns = [
    {
      label: "Date",
      key: row => `${row.id}date`,
      el: row => <Link to={`/admin/rounds/${row.id}`}>{dt(row.d)}</Link>
    },
    {
      label: "Name",
      key: row => `${row.id}name`,
      el: row => (
        <>
          {row.fn} {row.ln}
        </>
      )
    },
    {
      label: "Amount",
      key: row => `${row.id}amt`,
      el: row => <>{currency.format(row.amt)}</>
    }
  ];

  if (token)
    columns.push({
      label: "",
      key: row => `${row.id}actions`,
      el: row => (
        <>
          {token && (
            <Button variant="link" onClick={() => onDelete(row)}>
              X
            </Button>
          )}
        </>
      )
    });

  const onSubmit = ({ values }) => {
    api.saveAce({ values, token }).then(response => {
      if (response?.data?.success) {
        const player = playerData.find(p => p.id === parseInt(values.player));
        setAces([
          {
            ...values,
            fn: player?.fn,
            ln: player?.ln,
            id: response.data.newId
          },
          ...aces
        ]);
        setShowAceModal(false);
      }
    });
  };

  const onCancel = () => {
    setShowAceModal(false);
  };
  return (
    <div className="content">
      {acesLoading && "Loading Data..."}
      {aces && (
        <div>
          <Row>
            <Col>
              <h2>Current Ace Pot: ${aceTotal}</h2>
            </Col>
            <Col className="align-items-right">
              {token && (
                <>
                  <Button
                    onClick={() => {
                      setShowAceModal(true);
                    }}
                  >
                    Add Ace
                  </Button>

                  <Modal
                    size="lg"
                    show={showAceModal}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header>
                      <Modal.Title>Add Ace</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                      <FormSetup
                        onSubmit={onSubmit}
                        onCancel={onCancel}
                        fields={fieldData.map(f => ({
                          ...f,
                          options: players || []
                        }))}
                      />
                    </Modal.Body>
                  </Modal>
                </>
              )}
            </Col>
          </Row>

          <hr />
          <h3>Ace History</h3>
          <Table columns={columns} data={aces} rowKey={row => `row${row.id}`} />
        </div>
      )}
    </div>
  );
};

export default Aces;
