import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef
} from "react";
import { useParams } from "react-router-dom";
import { useApi } from "../../services/useApi";
//import * as api from "../../services/api";
import { Form, Button, Col } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import SiteContext from "../SiteContext";
//import merge from "deepmerge";
import { divisions, sortByProperties, dt } from "../helpers";
//import { calculate } from "./payouts";
import axios from "axios";
import { Link } from "react-router-dom";

import "./Entries.scss";

// function nextDate(dayIndex) {
//   var today = new Date();
//   today.setDate(
//     today.getDate() + ((dayIndex - 1 - today.getDay() + 7) % 7) + 1
//   );
//   return today;
// }

// const now = new Date();
// const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
// const weekStart =
//   new Date(today.setDate(today.getDate() - today.getDay())).getTime() / 1000;
// const weekEnd = nextDate(6).getTime() / 1000;

const CancelToken = axios.CancelToken;
let cancelApiCall;

const EntrieScores = () => {
  const { token, api } = useContext(SiteContext);

  const [round, setRound] = useState();
  const [aceTotal, setAceTotal] = useState();
  const [entries, setEntries] = useState();
  const [showNew, setShowNew] = useState(false);
  // const [editScore, setEditScore] = useState();
  // const [editDivision, setEditDivision] = useState();
  // const [editCreditAdded, setEditCreditAdded] = useState();
  // const [editCreditUsed, setEditCreditUsed] = useState();

  const { roundId } = useParams();

  const { loading: roundLoading, runApi: roundApi } = useApi({
    api: api.getRound
  });

  const { loading: acePotLoading, runApi: aceApi } = useApi({
    api: api.getAcePot
  });

  const { loading: entriesLoading, runApi: entriesApi } = useApi({
    api: api.getEntries
  });

  const getEntries = useCallback(() => {
    entriesApi({ roundId })
      .then(data => {
        setEntries(
          data.sort(
            sortByProperties([
              { field: "fn", asc: true },
              { field: "ln", asc: true }
            ])
          )
        );
      })
      .catch(() => setEntries([]));
  }, [entriesApi, roundId]);

  useEffect(() => {
    if (!entries && !entriesLoading) {
      getEntries();
    }
  }, [entries, entriesLoading, getEntries]);

  useEffect(() => {
    if (!aceTotal && !acePotLoading) {
      aceApi()
        .then(data => setAceTotal(data))
        .catch(() => setAceTotal(0));
    }
  }, [aceApi, acePotLoading, aceTotal]);

  useEffect(() => {
    if (!round && !roundLoading && roundId) {
      roundApi({ roundId })
        .then(data => {
          const newData = { ...data };
          setRound(newData);
        })
        .catch(() => setRound(0));
    }
  }, [round, roundApi, roundId, roundLoading]);

  const defaultValues = {
    player: "",
    division: "",
    credit_used: "0.00",
    credit_added: "0.00",
    score: "0",
    payout: "0.00",
    payoutAdjust: "0.00",
    ace: true
  };
  const [values, setValues] = useState(defaultValues);

  const doSearch = value => {
    if (value.length > 2) {
      if (cancelApiCall) cancelApiCall();
      return api
        .playerSearch(
          {
            text: value
          },
          {
            cancelToken: new CancelToken(function executor(c) {
              // An executor function receives a cancel function as a parameter
              cancelApiCall = c;
            })
          }
        )
        .then(d => {
          const opts = d.data
            .filter(p => !entries.some(i => i.player_id === p.id))
            .map(p => ({
              ...p,
              label: `${p.fn} ${p.ln} ($${p?.balance || "0"})`,
              value: p.id
            }));
          return opts;
        })
        .catch(function (thrown) {
          if (axios.isCancel(thrown)) {
            console.log("Request canceled", thrown.message);
          } else {
            alert("err");
          }
        });
    }
  };

  const handleOnChange = e => {
    setValues({ ...values, [e.target.id]: e.target.value });
  };

  const handleOnChangeCheckbox = e => {
    setValues({ ...values, [e.target.id]: !!e?.target?.checked });
  };

  const addPlayer = () => {
    api
      .addEntry({
        values: {
          ...values,
          player_id: values.player.id,
          ace: !!values.ace ? "1" : "0",
          round_id: roundId
        },
        token
      })
      .then(results => {
        if (values.player.division !== values.division) {
          api.savePlayer({
            values: { division: values.division },
            id: values.player.id,
            token
          });
        }

        if (results?.data?.newEntry) {
          setEntries([...entries, results.data.newEntry]);
        } else getEntries();

        resetForm();
      });
  };

  // const onDelete = e => {
  //   if (e) {
  //     const c = window.confirm(
  //       `Are you sure you want to delete ${e.fn} ${e.ln}?`
  //     );
  //     if (c)
  //       api.deleteEntry({ token, id: e.id }).then(response => {
  //         if (response?.data?.success) {
  //           setEntries([...entries.filter(x => x.id !== e.id)]);
  //           // getEntries();
  //         }
  //       });
  //   }
  // };

  const updateScore = ({ i, entry }) => {
    const score = i.currentTarget.value;

    if (score !== entry.score) {
      // update entry
      api
        .updateEntry({ values: { ...entry, score }, id: entry.id, token })
        .then(r => {
          if (r.data.success) {
            const entryToUpdate = entries.find(e => e.id === entry.id);
            entryToUpdate.score = parseInt(score);

            // setEntries(
            //   [...entries].sort(
            //     sortByProperties([
            //       { field: "division", asc: true },
            //       { field: "score", asc: true }
            //     ])
            //   )
            // );
            setEntries([...entries]);
            //  setEditScore(null);
          }
        });
    } else {
      // setEditScore(null);
    }
  };

  const resetForm = () => {
    setValues(defaultValues);
    setShowNew(false);
  };

  const selectAllText = e => {
    e.target.select();
  };

  // const doCalculations = useCallback(() => {
  //   if (!entries || !round) return null;

  //   if (entries.some(e => e.score === 0)) return null;

  //   const overwriteMerge = (destinationArray, sourceArray, options) =>
  //     sourceArray;

  //   const entriesReset = entries.map(e => ({ ...e, payout: 0 }));
  //   const payees = calculate({
  //     round,
  //     entries: entriesReset
  //   });

  //   const entriesWithPayouts = merge(payees, entriesReset, {
  //     arrayMerge: overwriteMerge
  //   });

  //   const newEntries = entriesWithPayouts.map(e => ({
  //     id: e.id,
  //     payout: e.payout
  //   }));
  //   const compareWithOrig = entries.map(e => ({
  //     id: e.id,
  //     payout: e.payout
  //   }));

  //   // don't update if the payouts haven't changed
  //   if (JSON.stringify(newEntries) === JSON.stringify(compareWithOrig))
  //     return null;

  //   const payload = {
  //     token,
  //     entries: newEntries
  //   };

  //   api
  //     .updatePayouts(payload)
  //     .then(r => {
  //       setEntries(entriesWithPayouts);
  //     })
  //     .catch(e => {
  //       console.log({ e });
  //     });

  //   // console.log({
  //   //   entries,
  //   //   payees,
  //   //   entriesWithPayouts,
  //   //   payload
  //   // });
  // }, [api, entries, round, token]);

  // update payouts after each update
  // useEffect(() => {
  //   if (entries) doCalculations();
  // }, [doCalculations, entries]);

  const pasteInput = useRef();

  const paste = () => {
    const v = pasteInput.current.value;

    const players = v.split(/\n/);

    const missing = [];

    players.forEach(p => {
      const [_n, s] = p.split(/\t/);
      const n = _n.split(" ").join("|").toLowerCase();
      const nameInput = document.getElementById(n);
      if (nameInput) nameInput.setAttribute("value", s);
      else missing.push(`${_n} - ${s}`);
      document.getElementById("missing").value = missing.join("\n");
    });
  };

  const savePasted = () => {
    const inputs = document.querySelectorAll("input.import");

    const data = Array.from(inputs)
      .filter(i => !!i.value)
      .map(i => ({
        id: i.dataset.id,
        value: i.value
      }));

    api.bulkEntryUpdate({ data, token }).then(r => {
      if (r.data.success) {
        window.location.reload();
      }
    });
  };

  if (!round) return null;

  if (round === 0) return <div>Error loading round</div>;

  return (
    <div className="content">
      <div className="scoresLink">
        <Link to={`/admin/round/play/${round.id}`}>BACK TO LIST</Link>
      </div>
      <div>
        <h4>{round?.n}</h4>
        <h5>
          {round?.loc} - {dt(round.d)}
        </h5>

        {!showNew && (
          <Button onClick={() => setShowNew(!showNew)}>Add Player</Button>
        )}
        {showNew && (
          <div id="FORM">
            <Form noValidate>
              <Form.Group>
                <Form.Label>Player</Form.Label>
                <AsyncSelect
                  id="player"
                  loadOptions={e => doSearch(e)}
                  onChange={o => {
                    setValues({ ...values, player: o, division: o.division });
                  }}
                  value={values.player}
                  hideIndicatorUntilSearch
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  id="division"
                  as="select"
                  value={values.division}
                  onChange={handleOnChange}
                >
                  <option>Choose...</option>
                  {divisions.map(d => (
                    <option key={d} value={d}>
                      {d}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Control
                    type="number"
                    id="credit_used"
                    value={values.credit_used}
                    onChange={handleOnChange}
                    onFocus={e => e.target.select()}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Control
                    type="number"
                    id="credit_added"
                    value={values.credit_added}
                    onChange={handleOnChange}
                    onFocus={e => e.target.select()}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Score</Form.Label>
                  <Form.Control
                    type="number"
                    id="score"
                    value={values.score}
                    onChange={handleOnChange}
                    onFocus={e => e.target.select()}
                  />
                </Form.Group>
                <Form.Group className="checkbox" as={Col}>
                  <Form.Check
                    type="checkbox"
                    id="ace"
                    label="Ace"
                    onChange={handleOnChangeCheckbox}
                    checked={values?.ace || false}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Group>
                <Button
                  onClick={addPlayer}
                  disabled={!values.player || !values.division}
                >
                  Add Player
                </Button>
                <Button onClick={resetForm} variant="light">
                  Cancel
                </Button>
              </Form.Group>
            </Form>
          </div>
        )}

        <br />
        <br />
        <div id="entries" className="scoring__wrapper">
          <table className="entry scoring">
            <thead>
              <tr>
                {/* <th></th> */}
                <th>Player</th>
                <th className="score">Score</th>
              </tr>
            </thead>
            <tbody>
              {entries &&
                entries.length > 0 &&
                entries.map(e => (
                  <tr key={e.id}>
                    {/* <td style={{ width: "50px" }}>
                      <Button variant="link" onClick={() => onDelete(e)}>
                        X
                      </Button>
                    </td> */}
                    <td>
                      {e.fn} {e.ln}
                    </td>
                    <td className="score">
                      <div>
                        <input
                          defaultValue={e.score}
                          //      autoFocus
                          onFocus={selectAllText}
                          type="number"
                          onBlur={i => {
                            updateScore({ entry: e, i });
                          }}
                          onKeyDown={i => {
                            if (i.key === "Enter") i.target.blur();
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <input
                        data-id={e.id}
                        id={`${e.fn.toLowerCase()}|${e.ln.toLowerCase()}`}
                        className="import"
                        type="number"
                        required
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="importColumn">
            <span>Missing:</span>
            <textarea type="text" id="missing" />
            <span>Paste here:</span>
            <button onClick={paste} type="button">
              Process Pasted Values
            </button>
            <textarea ref={pasteInput} type="text" id="import" />
          </div>
        </div>
        <div>
          <button onClick={savePasted} type="button">
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EntrieScores;
