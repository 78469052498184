// import { setDate } from "date-fns";
// import { set } from "lodash-es";
import { useState } from "react";

// const initialState = {
//   data: null,
//   isLoading: false,
//   error: false
// };

// const defaultReducer = (state, action) => {
//   switch (action.type) {
//     case "started":
//       return {
//         ...state,
//         isLoading: true,
//         error: null
//       };
//     case "succeeded":
//       return {
//         ...state,
//         data: action.payload,
//         isLoading: false,
//         error: false
//       };
//     case "failed":
//       return {
//         ...state,
//         data: action.payload,
//         isLoading: false,
//         error: true
//       };
//     default:
//       return state;
//   }
// };

// // Abstracted redux action to take any service function
// const defaultAction = dispatch => (service, ...args) => {
//   dispatch({ type: "started" });
//   service(...args).then(
//     response => {
//       dispatch({ type: "succeeded", payload: response.data });
//     },
//     error => {
//       dispatch({ type: "failed", payload: error });
//       logger.error({
//         description: error.toString(),
//         fatal: true
//       });
//     }
//   );
// };

export const useApi = ({ api }) =>
  // api // function from a services file (wrapped axios call)
  // init = initialState, // same as the redux initial state
  // reducer = defaultReducer, // optional custom reducer
  // action = defaultAction
  {
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState();
    const [error, setError] = useState();

    //const [data, dispatch] = useReducer(defaultReducer, initialState);

    // having this thunk is necessary for allowing custom actions. Otherwise async behavior is only possible where `dispatch` is in scope
    // const customDispatch = action => {
    //   if (typeof action === "function") {
    //     action(customDispatch);
    //   } else {
    //     dispatch(action);
    //   }
    // };

    // const asyncAction = defaultAction(customDispatch);

    // const apiCall = (...args) => {
    //   asyncAction(api, ...args);
    // };

    // const runApi = params => {
    //   setLoading(true);
    //   api(params)
    //     .then(response => {
    //       if (response.data) {
    //         setData({ data: response.data });
    //       } else {
    //         setError({ error: true });
    //       }
    //     })
    //     .finally(() => setLoading(false));
    // };

    const runApi = params => {
      return new Promise(function (resolve, reject) {
        setLoading(true);
        api(params)
          .then(response => {
            if (response.data) {
              setData({ data: response.data });
              resolve(response.data);
            } else {
              setError({ error: true });
              reject();
            }
          })
          .finally(() => setLoading(false));
      });
    };

    return { loading, runApi, error, data };

    // return useMemo(
    //   () => ({
    //     // quoteData,

    //   }),
    //   [
    //     // quoteData,

    //   ]
    // );
  };

// what if we need to add data to a context that is used by multiple components?

// is this just reimplementing redux using a context? Discussion would be helpful

// what if the context that needs to be updated is a form context? (ex. autofilling data on a form)
export const useApiContext = () => {};
