import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SiteContext from "./SiteContext";

import Rounds from "./Rounds";

const Home = () => {
  const { api } = useContext(SiteContext);

  const [rounds, setRounds] = useState();

  useEffect(() => {
    if (!rounds) {
      api
        .getRounds({ range: "all" })
        .then(function (response) {
          setRounds(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  });

  return (
    <Container>
      <Row>
        <Col className="">
          <Rounds range="future" data={rounds} />
        </Col>
        <Col>
          <Rounds range="past" data={rounds} />
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
