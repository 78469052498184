import React, { useState, useEffect, useContext, useRef } from "react";
//import { useHistory } from "react-router-dom";
import { useApi } from "../../services/useApi";
import cn from "classnames";
import { MultiSelect } from "react-multi-select-component";
import SiteContext from "../SiteContext";
//import FormSetup from "../FormSetup";
import { Button } from "react-bootstrap";
import { sortByProperties, dt } from "../helpers";

import "./Reports.scss";

const Jan1 = new Date(new Date().getFullYear(), 0, 1);
const Jan1Seconds = Jan1.getTime() / 1000;

const Reports = () => {
  const {
    //token,
    api
  } = useContext(SiteContext);

  //const history = useHistory();

  const [rounds, setRounds] = useState();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState([]);

  const { loading: roundsLoading, runApi: roundsApi } = useApi({
    api: api.getRounds
  });

  useEffect(() => {
    if (!rounds && !roundsLoading)
      roundsApi({ range: "past" }).then(d => {
        const opts = d
          .filter(r => r.d > Jan1Seconds)
          .sort(sortByProperties([{ field: "d", asc: true }]))
          .map(r => ({
            label: `${dt(r.d)} ${r.n} ${r["processed"]}`,
            value: r.id,
            d: r.d
          }));

        setRounds(opts);
      });
  }, [rounds, roundsApi, roundsLoading]);

  const load = () => {
    const roundIds = selected.map(i => i.value).join(",");
    api.getEntriesMulti({ roundIds }).then(e => setData(e.data));
    setShow(false);
  };

  const table = useRef();

  return (
    <div className="content">
      {roundsLoading && <div>LOADING...</div>}
      <div style={{ display: "flex" }}>
        <Button onClick={() => setShow(!show)}>Select Rounds</Button>
        <Button
          style={{ marginLeft: "auto" }}
          variant="warning"
          onClick={() => {
            const node = table.current;
            if (window.getSelection) {
              const selection = window.getSelection();
              const range = document.createRange();
              range.selectNodeContents(node);
              selection.removeAllRanges();
              selection.addRange(range);
            }
          }}
        >
          Select Table
        </Button>
      </div>

      <div id="roundList" className={cn({ show: show })}>
        <div className="closeList" onClick={() => setShow(!show)}>
          Close
        </div>
        {rounds && (
          <MultiSelect
            options={rounds}
            value={selected}
            onChange={setSelected}
            labelledBy="Select"
          />
        )}
        <div className="load-rounds" onClick={load}>
          Load Rounds
        </div>
      </div>

      {data && !!data.length && (
        <table className="table" ref={table} style={{ marginTop: "20px" }}>
          <thead>
            <tr>
              <th>Division</th>
              <th>First</th>
              <th>Last</th>
              <th>PDGA</th>
              {selected.map((s, i) => (
                <th key={i + 1}>R{i + 1}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map(d => (
              <tr key={d.playerId}>
                <td>{d.division}</td>
                <td>{d.fn}</td>
                <td>{d.ln}</td>
                <td>{d.pdga}</td>
                {selected.map((s, i) => (
                  <td key={d.playerId + i}>{d?.[`r${i + 1}`]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Reports;
