import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useApi } from "../services/useApi";
// import * as api from "../services/api";
import { Table } from "react-bootstrap";
import { currency, dt, divisions } from "./helpers";
import SiteContext from "./SiteContext";

import "./Rounds.scss";

const Round = () => {
  const { api } = useContext(SiteContext);
  let { roundId } = useParams();

  const {
    loading: roundLoading,
    runApi: roundApi
    //  data: round
    // error: roundApiError
  } = useApi({ api: api.getRound });

  const {
    loading: playersLoading,
    runApi: playersApi
    //    data: players
    //   error: playersApiError
  } = useApi({ api: api.getPlayersInRound });

  // useEffect(() => {
  //   console.log(roundData);
  // }, [roundData]);

  const [round, setRound] = useState();
  const [players, setPlayers] = useState();

  useEffect(() => {
    if (!round && !roundLoading) {
      roundApi({ roundId }).then(d => setRound(d));
    }

    if (!players && !playersLoading) {
      playersApi({ roundId }).then(d => setPlayers(d));
    }
  }, [
    players,
    playersApi,
    playersLoading,
    round,
    roundApi,
    roundId,
    roundLoading
  ]);

  const renderPlayersForDivision = div => {
    const playersFiltered = players.filter(p => {
      return p.division === div;
    });

    if (playersFiltered.length) {
      return (
        <div>
          <h4>{div}</h4>

          <Table className="round__table" striped size="sm">
            <thead>
              <tr>
                <th className="round__rank"></th>
                <th className="round__player">Player</th>
                <th className="round__ace">Ace</th>
                <th className="round__credit">- Credit</th>
                <th className="round__credit">+ Credit</th>
                <th className="round__score">Score</th>
                {/* <th className="round__par">Par</th> */}
                <th className="round__payout">Payout</th>
              </tr>
            </thead>
            <tbody>
              {playersFiltered.map((p, i) => (
                <tr key={p.id}>
                  <td className="round__rank">{i + 1}</td>
                  <td className="round__player">
                    <Link to={`/players/${p.player_id}`}>
                      {p.fn} {p.ln}
                    </Link>
                    <span className="round__player__pdga">{p.pdga}</span>
                  </td>
                  <td className="round__ace">{!!p.ace && "✓"}</td>
                  <td className="round__credit">
                    {!!p.credit_used && currency.format(p.credit_used)}
                  </td>
                  <td className="round__credit">
                    {!!p.credit_added && currency.format(p.credit_added)}
                  </td>
                  <td className="round__score">{p.score}</td>
                  {/* <td className="round__par">{p.par}</td> */}
                  <td className="round__payout">
                    {!!p.payout && currency.format(p.payout)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="content">
      {!round && "Loading Data..."}
      {round && (
        <div>
          <h3>
            {dt(round.d, "long")} at {round.loc} - {round.n}
          </h3>

          <br />
          {players && (
            <div>
              {divisions.map(div => {
                return (
                  <div key={div}>
                    <div>{renderPlayersForDivision(div)}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
      {/* 
      {!!data?.length && (
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Location</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map(d => {
              return (
                <tr key={d.d}>
                  <td>{format(new Date(d.d * 1000), "MM/dd/yyyy")}</td>
                  <td>{d.n}</td>
                  <td>{d.loc}</td>
                  <td>{!!d.processed && "✓"}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )} */}
    </div>
  );
};

export default Round;
