import React from "react";
import { Table as BSTable } from "react-bootstrap";
import cn from "classnames";
import "./Table.scss";

const Table = ({ columns, data, rowKey }) => {
  //  console.log({ columns, data });
  return (
    <BSTable striped bordered hover size="sm">
      <thead>
        <tr>
          {columns.map(c => {
            return <th key={c.key || c.label}>{c.label}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {data.map(d => (
          <tr key={rowKey(d)}>
            {columns.map(c => {
              const classes = cn({ [`table__${c.align}`]: c.align });
              return (
                <td key={c.key(d)} className={classes}>
                  {c.el(d)}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </BSTable>
  );
};

export default Table;
