import React, { useState, useContext } from "react";
//import * as api from "../services/api";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";

import SiteContext from "./SiteContext";

import "./Login.scss";

const CryptoJS = require("crypto-js");
const cryptKey = "discgolfisfun!";

const Login = () => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [err, setErr] = useState();

  const { saveToken, api } = useContext(SiteContext);

  const history = useHistory();

  const handleSubmit = async e => {
    setErr("");
    e.preventDefault();
    // encrypt password
    const p = CryptoJS.AES.encrypt(password, cryptKey).toString();

    const loginData = await api.login({
      username,
      password: p
    });

    if (loginData?.data?.error) {
      setErr(loginData.data.error);
    } else {
      saveToken(loginData.data.token);
      history.push("/admin/rounds");
    }
  };

  return (
    <div className="login-wrapper">
      <br />
      <div className="error">{err}</div>
      <br />
      <form onSubmit={handleSubmit} className="login-form">
        <label>
          <p>Username</p>
          <input
            id="u"
            name="u"
            type="text"
            onChange={e => setUserName(e.target.value)}
            value={username}
            autoFocus
          />
        </label>
        <label>
          <p>Password</p>
          <input
            id="p"
            name="p"
            type="password"
            onChange={e => setPassword(e.target.value)}
            value={password}
          />
        </label>
        <br />

        <div>
          <Button type="submit" variant="secondary">
            Login
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Login;
