import React, { useContext, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import SiteContext from "./SiteContext";

const Navigation = () => {
  const { token, clearToken } = useContext(SiteContext);
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar
      expand="sm"
      expanded={expanded}
      bg={!token ? "dark" : "info"}
      variant="dark"
    >
      <Navbar.Brand as={Link} to={!token ? "/" : "/admin/rounds"}>
        ProFlight PDGA League
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        onClick={() => setExpanded(expanded ? false : "expanded")}
      />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          {!token ? (
            <>
              <Nav.Link as={Link} to="/" onClick={() => setExpanded(false)}>
                Rounds
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/players"
                onClick={() => setExpanded(false)}
              >
                Players
              </Nav.Link>
            </>
          ) : (
            <>
              <Nav.Link
                as={Link}
                to="/admin/rounds"
                onClick={() => setExpanded(false)}
              >
                Rounds
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/players"
                onClick={() => setExpanded(false)}
              >
                Players
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/transfers"
                onClick={() => setExpanded(false)}
              >
                Transfers
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/admin/reports"
                onClick={() => setExpanded(false)}
              >
                Reports
              </Nav.Link>
            </>
          )}
          <Nav.Link as={Link} to="/aces" onClick={() => setExpanded(false)}>
            Aces
          </Nav.Link>

          {/* {(
          <NavDropdown title="Admin" id="collasible-nav-dropdown">
            <NavDropdown.Item to="/admin/rounds">Rounds</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item to="/admin/Players">Players</NavDropdown.Item>
          </NavDropdown>
        )} */}
        </Nav>
        <Nav>
          {!token && (
            <Nav.Link as={Link} to="/admin">
              Admin
            </Nav.Link>
          )}
          {token && (
            <Nav.Link className="push-right" onClick={clearToken}>
              LogOut
            </Nav.Link>
          )}
        </Nav>
        {/* <Nav>
  <Nav.Link href="#deets">More deets</Nav.Link>
  <Nav.Link eventKey={2} href="#memes">
    Dank memes
  </Nav.Link>
</Nav> */}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
