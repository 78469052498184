import React from "react";
import { Route, Switch } from "react-router-dom";

import Aces from "./components/Aces";
import Admin from "./components/admin/Admin";
import Home from "./components/Home";
import Login from "./components/Login";
import Navigation from "./components/Navigation";
import Player from "./components/Player";
import Players from "./components/Players";
import PlayersAll from "./components/PlayersAll";
import Reports from "./components/admin/Reports";
import Round from "./components/Round";
import Rounds from "./components/Rounds";
import SiteContext from "./components/SiteContext";
import Transfers from "./components/Transfers";
import useSite from "./components/useSite";

import "./styles/styles.scss";
//import "bootstrap/dist/css/bootstrap.min.css";

export default function App() {
  const SiteStateValue = useSite();

  return (
    <SiteContext.Provider value={SiteStateValue}>
      <Navigation />
      <div>
        {/*   <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/rounds">Rounds</Link>
          </li>
          <li>
            <Link to="/players">Players</Link>
          </li>
        </ul>
*/}
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/players" exact>
            <Players />
          </Route>
          <Route path="/players-all" exact>
            <PlayersAll />
          </Route>
          <Route path="/players/:playerId" exact>
            <Player />
          </Route>
          <Route path="/rounds" exact>
            <Rounds />
          </Route>
          <Route path="/rounds/:roundId" exact>
            <Round />
          </Route>
          <Route path="/admin/reports/" exact>
            <Reports />
          </Route>
          <Route path="/aces" exact>
            <Aces />
          </Route>
          <Route path="/transfers" exact>
            <Transfers />
          </Route>

          <Route path="/admin">
            <Admin />
          </Route>

          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </SiteContext.Provider>
  );
}

// function Topics() {
//   let match = useRouteMatch();

//   return (
//     <div>
//       <h2>Topics</h2>

//       <ul>
//         <li>
//           <Link to={`${match.url}/components`}>Components</Link>
//         </li>
//         <li>
//           <Link to={`${match.url}/props-v-state`}>Props v. State</Link>
//         </li>
//       </ul>

//       {/* The Topics page has its own <Switch> with more routes
//           that build on the /topics URL path. You can think of the
//           2nd <Route> here as an "index" page for all topics, or
//           the page that is shown when no topic is selected */}
//       <Switch>
//         <Route path={`${match.path}/:topicId`}>
//           <Topic />
//         </Route>
//         <Route path={match.path}>
//           <h3>Please select a topic.</h3>
//         </Route>
//       </Switch>
//     </div>
//   );
// }

// function Topic() {
//   let { topicId } = useParams();
//   return <h3>Requested topic ID: {topicId}</h3>;
// }
