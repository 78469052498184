import React from "react";
// import * as api from "../services/api";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { dt, sortByProperties } from "./helpers";

const Rounds = ({ data = [], range }) => {
  const today = new Date();
  const now = today.getTime() / 1000;
  const roundData = () => {
    if (!data.length) return [];

    if (range === "future")
      return data
        .filter(r => r.d > now)
        .sort(sortByProperties([{ field: "d", asc: true }]));

    if (range === "past")
      return data
        .filter(r => r.d < now)
        .sort(sortByProperties([{ field: "d", asc: false }]));
  };
  // console.log({ roundData, data, now });

  return (
    <div className="content">
      <h3 className="title">{range} Rounds</h3>
      {!data && "Loading Data..."}

      {!!data?.length && (
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Location</th>
            </tr>
          </thead>
          <tbody>
            {roundData().map(d => {
              return (
                <tr key={d.d}>
                  <td>
                    <Link to={`/rounds/${d.id}`}>{dt(d.d)}</Link>
                  </td>
                  <td>{d.n}</td>
                  <td>{d.loc}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default Rounds;
