import React, { useEffect, useState } from "react";

import { Form, Button } from "react-bootstrap";
import Select from "react-select";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./Forms.scss";

const FormSetup = ({ fields, onSubmit, onCancel }) => {
  const initialValues = Object.fromEntries(
    fields.map(f => [f.name, f?.default || ""])
  );

  useEffect(() => {
    const newValues = Object.fromEntries(
      fields.map(f => [f.name, f?.default || ""])
    );
    setValues(newValues);
  }, [fields]);

  const [submitted, setSubmitted] = useState(false);

  const [values, setValues] = useState(initialValues);

  const [validated, setValidated] = useState(false);

  const handleOnChange = (e, t, i, v) => {
    const type = e?.currentTarget?.type || t || null;
    const id = e?.currentTarget?.id || i || t?.name;
    const value = (
      e?.currentTarget?.value ||
      e?.target?.value ||
      e?.value ||
      v ||
      ""
    ).toString();

    if (
      type === "text" ||
      t?.action === "select-option" ||
      e?.target?.tagName === "SELECT"
    ) {
      setValues({ ...values, [id]: value });
    }

    // if (e?.target?.tagName === "SELECT") setValues({ ...values, [id]: value });

    if (type === "date") {
      const v = new Date(value).getTime() / 1000;
      const offset = new Date(value).getTimezoneOffset();
      const milliOffset = offset * 60;

      setValues({
        ...values,
        [id]: v - milliOffset
      });
    }

    // checkbox
    if (e?.target?.classList.contains("form-check-input"))
      setValues({ ...values, [id]: !!e?.target?.checked });
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    setSubmitted(true);

    const form = event.currentTarget;

    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) onSubmit({ values });
  };

  return (
    <div className="content">
      {
        <div>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            {fields.map(f => {
              if (f.type === "text") {
                return (
                  <Form.Group controlId={f.name} key={f.name}>
                    <Form.Label>{f.label}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={f.label}
                      value={values?.[f.name] || ""}
                      onChange={handleOnChange}
                      required={f?.required}
                      isInvalid={submitted && f?.required && !values?.[f.name]}
                    />
                  </Form.Group>
                );
              }

              if (f.type === "checkbox") {
                return (
                  <Form.Group controlId={f.name} key={f.name}>
                    <Form.Check
                      type="checkbox"
                      id={f.name}
                      label={f.label}
                      onChange={handleOnChange}
                      checked={values?.[f.name] || false}
                    />
                  </Form.Group>
                );
              }

              if (f.type === "select") {
                return (
                  <Form.Group controlId={f.name} key={f.name}>
                    <Form.Label>{f.label}</Form.Label>

                    <Form.Control
                      id={f.name}
                      data-live-search="true"
                      type="select"
                      as="select"
                      custom
                      placeholder={f.label}
                      value={values?.[f.name] || ""}
                      onChange={handleOnChange}
                      required={f?.required}
                      isInvalid={submitted && f?.required && !values?.[f.name]}
                    >
                      <option></option>

                      {(f?.options || []).map(o => {
                        return (
                          <option key={o?.key + o.value} value={o.value}>
                            {o?.label || o.value}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                );
              }

              if (f.type === "searchSelect") {
                return (
                  <Form.Group controlId={f.name} key={f.name}>
                    <Form.Label>{f.label}</Form.Label>
                    <Select
                      name={f.name}
                      options={f?.options || []}
                      onChange={handleOnChange}
                      value={f?.options.find(o => o.value === values?.[f.name])}
                      isSearchable
                      isClearable
                    />
                  </Form.Group>
                );
              }

              if (f.type === "date") {
                return (
                  <Form.Group controlId={f.name} key={f.name}>
                    <Form.Label>{f.label}</Form.Label>
                    <DatePicker
                      id={f.name}
                      name={f.name}
                      type="date"
                      className="form-control"
                      selected={
                        values?.[f.name]
                          ? new Date(values?.[f.name] * 1000)
                          : ""
                      }
                      onChange={date =>
                        handleOnChange(null, "date", f.name, date)
                      }
                      dateFormat="MM/dd/yyyy"
                      required={f?.required}
                      isInvalid={submitted && f?.required && !values?.[f.name]}
                    />
                  </Form.Group>
                );
              }

              return null;
            })}
            <Button variant="link" type="button" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Form>
        </div>
      }
    </div>
  );
};

export default FormSetup;
