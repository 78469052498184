import React, { useContext, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import Entries from "./Entries";
import EntriesScores from "./EntriesScores";
import Player from "./Player";
import User from "./User";
import Round from "./Round";
import Rounds from "./Rounds";
import SiteContext from "../SiteContext";

const CryptoJS = require("crypto-js");

const Admin = () => {
  const history = useHistory();

  const { token, clearToken } = useContext(SiteContext);
  const cryptKey = process.env.REACT_APP_CRYPTO;

  useEffect(() => {
    if (!token) {
      history.push("/login");
    } else {
      const timestamp = CryptoJS.AES.decrypt(token, cryptKey).toString(
        CryptoJS.enc.Utf8
      );

      const t = parseInt(timestamp);
      const d = new Date(t);
      const today = new Date();

      const diffTime = Math.abs(today - d);
      //const diffMinutes = Math.ceil(diffTime / (1000 * 60));
      const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
      //const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffHours > 72) {
        clearToken();
        history.push("/login");
      }
    }
  }, [clearToken, cryptKey, history, token]);

  return (
    <Switch>
      <Route path="/admin/round/play/:roundId">
        <Entries />
      </Route>
      <Route path="/admin/round/scores/:roundId">
        <EntriesScores />
      </Route>
      <Route path="/admin/rounds/new" exact>
        <Round />
      </Route>
      <Route path="/admin/rounds/:roundId" exact>
        <Round />
      </Route>
      <Route path="/admin/rounds">
        <Rounds />
      </Route>
      <Route path="/admin/player">
        <Player />
      </Route>
      <Route path="/admin/user">
        <User />
      </Route>
    </Switch>
  );
};

export default Admin;
