import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { sortByProperties, dt } from "../helpers";
import { useApi } from "../../services/useApi";
import SiteContext from "../SiteContext";
import cn from "classnames";
// import * as api from "../../services/api";
import { Button } from "react-bootstrap";

import Table from "../Table";

// function nextDate(dayIndex) {
//   var today = new Date();
//   today.setDate(
//     today.getDate() + ((dayIndex - 1 - today.getDay() + 7) % 7) + 1
//   );
//   return today;
// }

//const now = new Date();
//const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
// const weekStart =
//   new Date(today.setDate(today.getDate() - today.getDay())).getTime() / 1000;
// const weekEnd = nextDate(6).getTime() / 1000;

const Rounds = ({ range = "all" }) => {
  const { api } = useContext(SiteContext);

  const [data, setData] = useState();
  const [which, setWhich] = useState("past");
  const history = useHistory();

  const { loading: roundsLoading, runApi: roundsApi } = useApi({
    api: api.getRounds
  });

  useEffect(() => {
    if (!data && !roundsLoading)
      roundsApi({ range }).then(d => {
        setData(d.sort(sortByProperties([{ field: "d", asc: false }])));
      });
  }, [data, range, roundsApi, roundsLoading]);

  const columns = [
    {
      label: "Date",
      key: row => `${row.id}date`,
      el: row => <Link to={`/admin/round/play/${row.id}`}>{dt(row.d)}</Link>
    },
    {
      label: "Name",
      key: row => `${row.id}name`,
      el: row => <Link to={`/admin/round/play/${row.id}`}>{row.n}</Link>
    },
    {
      label: "Location",
      key: row => `${row.id}loc`,
      el: row => <>{row.loc}</>
    },
    {
      label: "",
      key: row => `${row.id}proc`,
      el: row => <>{!!row.processed && "✓"}</>,
      align: "center"
    },
    {
      label: "",
      key: row => `${row.id}go`,
      el: row => (
        <>
          {
            //row.d > weekStart && row.d < weekEnd &&
            <Button
              size="sm"
              variant="link"
              onClick={() => history.push(`/admin/rounds/${row.id}`)}
            >
              Edit
            </Button>
          }
        </>
      )
    }
  ];

  const today = new Date();
  const now = today.getTime() / 1000;

  return (
    <div className="content">
      <Link to="/admin/rounds/new">Add New Round</Link>
      <br />
      <br />

      {roundsLoading && "Loading Data..."}

      {!!data?.length && (
        <>
          <h3>
            <span
              className={cn("clickable", { active: which === "past" })}
              onClick={() => setWhich("past")}
            >
              PAST
            </span>{" "}
            /{" "}
            <span
              className={cn("clickable", { active: which === "future" })}
              onClick={() => setWhich("future")}
            >
              FUTURE
            </span>
          </h3>
          {which === "past" && (
            <Table
              columns={columns}
              data={data.filter(r => r.d < now)}
              rowKey={row => `row${row.id}`}
            />
          )}
          {which === "future" && (
            <Table
              columns={columns}
              data={data
                .filter(r => r.d > now)
                .sort(sortByProperties([{ field: "d", asc: true }]))}
              rowKey={row => `row${row.id}`}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Rounds;
